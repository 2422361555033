import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Card from "../components/card"
import CardNodo from "../components/cardNodo"
import BlueSpan from "../components/blueSpan"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAnchor } from "@fortawesome/free-solid-svg-icons"

const nodi = [
  {
    title: "Gassa D'amante",
    filename: "nodi-marinari-gassa-di-amante.jpg",
    link: "nodi-marinari-a-occhio",
    description:
      "È un nodo poco complesso e, certamente il piu conosciuto ed eseguito. Il pregio di questo nodo è di non essere scorsoio e di non stringersi troppo; inoltre, pur essendo una nodo molto sicuro, può essere sciolto facilmente, anche quando la corda è bagnata. Si può fare con tutti i tipi di corda.",
  },
  {
    title: "Gassa Spagnola",
    filename: "nodi-marinari-gassa-spagnola.jpg",
    link: "nodi-marinari-a-occhio",
    description:
      "Questo nodo antico viene impiegato in varie situazioni: per il recupero dell'uomo in mare, per issare oggetti in posizione orizzontale come scale, assi, ecc. Il nodo consiste in due occhi divaricati e indipendenti che, anche se sottoposti a forte trazione, hanno una buona tenuta e offrono la massima sicurezza. Eseguita su grossi cavi, la gassa spagnola sostiene il peso di scale che potrebbero servire come ponteggi.",
  },
  {
    title: "Gassa d'amante doppia senza cima",
    filename: "nodi-marinari-gassa-di-amante-doppia-senza-cima.jpg",
    link: "nodi-marinari-a-occhio",
    description:
      "La gassa d'amante con cima doppia consiste in due occhi fissi, dello stesso diametro, sovrapposti l'uno all'altro ma che, divaricati, si possono usare separatamente.",
  },
  {
    title: "Gassa Tripla o Incappellaggio",
    filename: "nodi-marinari-gassa-tripla-o-incappellaggio.jpg",
    link: "nodi-marinari-a-occhio",
    description:
      "La Gassa tripla, detta anche nodo di incappellaggio, serve ai velisti in situazioni di emergenza per sostenere un albero di fortuna: con l'occhio centrale della gassa si incappella l'albero e agli altri tre occhi vengono fissati, con nodi di scotta gli stralli e le sartie.",
  },
]

const AOcchio = () => (
  <Layout>
    <SEO title="Nodi Marinari a occhio o Gasse" />
    <h1 className="text-center text-blue-500 font-bold text-3xl mt-12">
      <FontAwesomeIcon
        className="text-blue-600 stroke-current text-black"
        size="lg"
        icon={faAnchor}
      />{" "}
      I nodi marinari a Occhio o Gasse{" "}
      <FontAwesomeIcon
        className="text-blue-600 stroke-current text-black"
        size="lg"
        icon={faAnchor}
      />
    </h1>
    <div className="container mx-auto">
      <div className="font-serif shadow p-5 text-justify italic text-xl mt-5">
        I <BlueSpan>nodi marinari a occhio</BlueSpan> meglio conosciuti come{" "}
        <BlueSpan>nodi marinari a gasse</BlueSpan> sono solitamente eseguite
        all'estremità del cavo, ripiegando il corrente a cappio o ad asola e
        annodandolo sul dormiente, senza che scorra. A differenza dei nodi di
        avvolgimento, che vengono eseguiti direttamente sull'oggetto e ne
        seguono la forma, le gasse vengono solitamente eseguiti nelle mani ed in
        seguito incappellati all'oggetto.
        <br />
        {nodi.map(nodo => (
          <CardNodo
            filename={nodo.filename}
            nomeNodo={nodo.title}
            description={nodo.description}
          ></CardNodo>
        ))}
      </div>
      <Card className="mt-5"></Card>
    </div>
  </Layout>
)

export default AOcchio
